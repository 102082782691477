<template>
	<div class="main-wrapper">
		<div class="d-flex justify-content-between ">
			<h4 class="mb-4">
				User info
			</h4>
			<div class="typo-body-2 color-black-45">
				<span>User group: </span>
				<strong>{{ profile.data.group }}</strong>
			</div>
		</div>
		<BaseLoading
			v-if="profile.isLoading"
			is-fullpage
		/>
		<CForm
			v-else
			@submit.prevent="submit"
		>
			<CRow align-horizontal="center">
				<CCol lg="6">
					<CInput
						v-model.trim="staffId"
						:label="$t('page.users.create.formLabel.staffId')"
						disabled
					/>
				</CCol>
				<CCol lg="6">
					<CInput
						v-model.trim="$v.username.$model"
						:label="$t('page.users.create.formLabel.username')"
						:is-valid="isValidUsername"
						disabled
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="6">
					<CInput
						v-model.trim="$v.firstName.$model"
						:label="`${$t('page.users.create.formLabel.firstName')}*`"
						:is-valid="isValidFirstName"
						:invalid-feedback="getValidateFirstNameMessage"
					/>
				</CCol>
				<CCol lg="6">
					<CInput
						v-model.trim="$v.lastName.$model"
						:label="$t('page.users.create.formLabel.lastName')"
						:is-valid="isValidLastName"
						:invalid-feedback="getValidateLastNameMessage"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="6">
					<CInput
						v-model.trim="provider"
						:label="$t('page.users.create.formLabel.provider')"
						disabled
					/>
				</CCol>
				<CCol lg="6" />
			</CRow>
			<CRow v-if="!isSocialProvider" align-horizontal="center">
				<CCol lg="12">
					<div v-if="!isAllowChangePassword">
						<div class="label">
							Password
						</div>
						<CButton
							color="tertiary"
							class="mb-4"
							@click="handleAllowChangePassword"
						>
							Change password
						</CButton>
					</div>
					<CRow v-else>
						<CCol>
							<CInput
								v-model.trim="$v.oldPassword.$model"
								:is-valid="!$v.oldPassword.$error && null"
								:invalid-feedback="errorOldPasswordMessage"
								:description="getOldPasswordDescription"
								type="password"
								label="Old password*"
							/>
						</CCol>
						<CCol>
							<CInput
								v-if="isAllowChangePassword"
								v-model.trim="$v.newPassword.$model"
								:is-valid="!$v.newPassword.$error && null"
								:invalid-feedback="errorNewPasswordMessage"
								type="password"
								label="New password"
							/>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.email.$model"
						:label="$t('page.users.create.formLabel.email')"
						:is-valid="isValidEmail"
						:invalid-feedback="getValidateEmailMessage"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center">
				<CCol lg="12">
					<CInput
						v-model.trim="$v.phone.$model"
						:label="$t('page.users.create.formLabel.phone')"
						:is-valid="isValidPhone"
						:invalid-feedback="getValidatePhoneMessage"
					/>
				</CCol>
			</CRow>
			<CRow align-horizontal="center" class="mb-5">
				<CCol lg="12">
					<CTextarea
						v-model.trim="$v.note.$model"
						:label="$t('page.users.create.formLabel.note')"
						:is-valid="isValidNote"
						:invalid-feedback="getValidateNoteMessage"
					/>
				</CCol>
			</CRow>
			<BaseActionPanelStickyFooter
				:disabled-confirm="profile.isUpdating"
				is-edit
				@onConfirm="submit"
				@onCancel="$router.go(-1)"
			/>
		</CForm>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators';
import { validateHandler, checkIfValid } from '../assets/js/selectors/global/validation';
import { scrollToTop } from '../assets/js/helpers';
import { SOCIAL_PROVIDER_GROUP_LIST } from '../enums/social';

export default {
	validations() {
		const schema = {
			username: {
				maxLength: maxLength(255),
			},
			firstName: {
				required,
				maxLength: maxLength(35),

			},
			lastName: {
				maxLength: maxLength(35),
			},
			email: {
				format(value) {
					return email(value);
				},
				maxLength: maxLength(255),
			},
			phone: {
				maxLength: maxLength(255),
			},
			note: {
				maxLength: maxLength(150),
			},
		};

		if (this.isAllowChangePassword) {
			schema.oldPassword = {
				required,
				minLength: minLength(8),
				maxLength: maxLength(255),
			};

			schema.newPassword = {
				required,
				minLength: minLength(8),
				maxLength: maxLength(255),
				notSameOldPassword(value) {
					return value !== this.oldPassword;
				},
			};
		}

		return schema;
	},
	data() {
		return {
			isLoading: false,

			staffId: null,
			username: null,
			oldPassword: null,
			newPassword: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			note: null,
			provider: null,
			status: null,
			isAllowChangePassword: false,
		};
	},
	computed: {
		...mapState('profile', {
			profile: 'profile',
		}),

		isValidUsername() {
			const field = this.$v.username;
			return checkIfValid(field);
		},
		isValidEmail() {
			const field = this.$v.email;
			return checkIfValid(field);
		},
		isValidPhone() {
			const field = this.$v.phone;
			return checkIfValid(field);
		},
		isValidFirstName() {
			const field = this.$v.firstName;
			return checkIfValid(field);
		},
		isValidLastName() {
			const field = this.$v.lastName;
			return checkIfValid(field);
		},
		isValidNote() {
			const field = this.$v.note;
			return checkIfValid(field);
		},

		errorOldPasswordMessage() {
			if (!this.$v.oldPassword.required) {
				return this.$t('page.users.create.validationMessage.password.required');
			}

			if (!this.$v.oldPassword.minLength || !this.$v.oldPassword.maxLength) {
				return this.$t('page.users.create.validationMessage.password.lengthLimit');
			}

			return null;
		},
		errorNewPasswordMessage() {
			if (!this.$v.newPassword.required) {
				return this.$t('page.users.create.validationMessage.password.required');
			}

			if (!this.$v.newPassword.minLength || !this.$v.newPassword.maxLength) {
				return this.$t('page.users.create.validationMessage.password.lengthLimit');
			}

			if (!this.$v.newPassword.notSameOldPassword) {
				return this.$t('page.users.create.validationMessage.password.notSameOldPassword');
			}

			return null;
		},
		getValidateEmailMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				existMessage: this.$t('page.users.create.validationMessage.email.exists'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.email.lengthLimit'),
				invalidFormatMessage: this.$t('page.users.create.validationMessage.email.invalidFormat'),
			});
		},
		getValidatePhoneMessage() {
			const field = this.$v.email;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.phone.lengthLimit'),
			});
		},
		getValidateFirstNameMessage() {
			const field = this.$v.firstName;

			return validateHandler(field, {
				requiredMessage: this.$t('page.users.create.validationMessage.firstName.required'),
				lengthLimitMessage: this.$t('page.users.create.validationMessage.firstName.lengthLimit'),
			});
		},
		getValidateLastNameMessage() {
			const field = this.$v.lastName;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.lastName.lengthLimit'),
			});
		},
		getValidateNoteMessage() {
			const field = this.$v.note;
			return validateHandler(field, {
				lengthLimitMessage: this.$t('page.users.create.validationMessage.note.lengthLimit'),
			});
		},
		getOldPasswordDescription() {
			const field = this.$v.oldPassword;

			if (field.$error) {
				return '';
			}
			return this.$t('page.users.create.description.password');
		},

		isSocialProvider() {
			return SOCIAL_PROVIDER_GROUP_LIST.map((item) => item.provider).includes(this.provider);
		},
	},
	async created() {
		this.isLoading = true;

		// Set form data
		this.staffId = this.profile.data.staffId;
		this.username = this.profile.data.username;
		this.email = this.profile.data.email;
		this.phone = this.profile.data.phoneNumber;
		this.firstName = this.profile.data.firstName;
		this.lastName = this.profile.data.lastName;
		this.status = this.profile.data.status;
		this.note = this.profile.data.note;
		this.provider = this.profile.data.provider;

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			updateProfile: 'profile/updateProfile',
			getWarehouseList: 'warehouses/getWarehouseList',
			getRoleList: 'roles/getRoleList',
		}),

		async submit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			const params = {
				email: this.email,
				// ?? FYI: not it-easy
				password: this.newPassword,
				old_password: this.oldPassword,
				first_name: this.firstName,
				last_name: this.lastName,
				phone_number: this.phone,
				note: this.note,
			};

			try {
				await this.updateProfile(params);

				this.isAllowChangePassword = false;
			} catch {
				// Do nothing
			}
		},
		handleAllowChangePassword() {
			this.isAllowChangePassword = true;
		},
	},
};
</script>
